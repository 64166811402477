<template>
  <div>
    <Loader v-if="activeDay && (!this.eventUsers || this.eventUsers.length == 0)" />
    <!-- <Loader v-if="(!eventShiftsDays || eventShiftsDays.length == 0) || !activeDay" /> -->
    <div class="dashboard__container pt-3">

      <div class="dashboard__container--header flex-wrap" v-if="eventShiftsDays && eventShiftsDays.length > 0">
        <div class="whiteBack mb-5" style="width:50%; min-width: 26rem;">
          <label for="pickDay" class="mb-2">Choose Day:</label>
          <v-select
            label="title" 
            :options="eventShiftsDays"
            clearable
            v-model="activeDay"
            @input="setActiveDay"
            >
            <template #option="option">
              {{option.title | moment("dddd, MMM Do, YYYY") }}
            </template>
          </v-select>
        </div>
        
      </div>

      <div class="dashboard__container--body" v-if="activeDay">
        <div class="dashboard__container--body--col alt-col" style="width:100%;">
          <span v-for="shift in activeShifts" :key="shift.id">
            <div class="card mb-4">
              <div class="flex align-center justify-space-between mb-3">
                <span>
                  <h4><span v-if="activeDay">{{activeDay | moment("dddd, MMM Do") }}</span></h4>
                  <div class="caption strong" v-if="shift.position">Position: {{shift.position.label || shift.position.title}}</div>
                  
                  <div class="caption" v-if="shift.startTime">Shift Times: {{ [ shift.startTime, "HH:mm" ] | moment("hh:mm A") }}<span v-if="shift.endTime"> - {{ [ shift.endTime, "HH:mm" ] | moment("hh:mm A") }}</span></div>

                  <div class="mb-3">
                    <div class="caption">Roster Total: {{orderedPlacedUsers(shift).length}} / {{shift.staff}}</div>
                    <div class="caption">Checked-In: {{checkedInUsers(shift).length}}</div>
                  </div>

                  

                  <!-- <div v-if="(checkedInUsers(shift).length >= 1) && (eventInfo.reportEmails && eventInfo.reportEmails.length >= 1)">
                    <div class="caption mb-3">Send Report To: {{eventInfo.reportEmails}}</div>
                    <button class="btn btn__primary" @click="submitReport(shift)">Submit Report
                      <transition name="fade">
                        <span class="ml-2" v-if="shift.reportSending">
                        <i class="fa fa-spinner fa-spin"></i>
                        </span>
                      </transition>
                    </button>

                    <span v-if="shift.nightlyReportSent" class="caption mt-2">
                      Last Sent: {{shift.nightlyReportSent.toDate() | moment("MMMM Do YYYY, h:mm a") }}
                    </span>
                  </div> -->

                </span>
                <div>
                  <button class="btn btn__icon" @click="expand(shift)" v-if="shift.collapse"><i class="fas fa-chevron-up"></i></button>
                  <button class="btn btn__icon" @click="collapse(shift)" v-if="!shift.collapse"><i class="fas fa-chevron-down"></i></button>
                </div>
              </div>
            </div>
            <transition name="fade">
              <div v-if="shift.collapse == true">
                

                <div class="card mb-4 checkInCard" v-for="(item, index) in orderedPlacedUsers(shift)" :key="index" :class="{ warning: (item.checkInTimeStamp && !item.checkOutTimeStamp), success: ((item.checkInTimeStamp && item.checkOutTimeStamp) || (item.inTime && item.outTime)) }">
                  <span class="flex flex-wrap">
                    <img :src="(item.photoUrl || `https://firebasestorage.googleapis.com/v0/b/mvpes-25aef.appspot.com/o/avatar%20copy.png?alt=media&token=966c07c4-125a-490f-81be-4e2d26bf33fa`)" alt="" style="width: 6rem; height:6rem; border-radius: 50%; padding: 0.25rem;">
                    <div class="ml-3 flex flex-column">
                      <span class="strong mb-2">{{item.fullName}}</span>
                      <span class="caption mb-2" v-if="item.inTime">Checked-In: {{ [ item.inTime, "HH:mm" ] | moment("hh:mm A") }}
                        <button class="btn btn__icon ml-3" @click="removeIn(item)"><i class="fa-solid fa-xmark danger"></i></button>
                      </span>

                      <!-- <span class="caption mb-2" v-if="item.inTime">Checked-In: {{formatDate2( item.checkInTimeStamp )}}
                        <button class="btn btn__icon ml-3" @click="removeIn(item)"><i class="fa-solid fa-xmark danger"></i></button>
                      </span> -->

                      <span class="caption" v-if="item.outTime">Checked-Out: {{ [ item.outTime, "HH:mm" ] | moment("hh:mm A") }}<button class="btn btn__icon ml-3" @click="removeOut(item)"><i class="fa-solid fa-xmark danger"></i></button></span>

                      <!-- <span class="caption mb-2" v-if="item.outTime">Checked-Out: {{formatDate2( item.checkOutTimeStamp )}}
                        <button class="btn btn__icon ml-3" @click="removeOut(item)"><i class="fa-solid fa-xmark danger"></i></button>
                      </span> -->

                      <span class="caption mt-2" v-if="item.breakTime">Break Time: 30 Mins<button class="btn btn__icon ml-3" @click="removeBreak(item)"><i class="fa-solid fa-xmark danger"></i></button></span>

                      <!-- <span v-if="(item.checkInTimeStamp && item.checkOutTimeStamp) && (!item.inTime && !item.outTime)" class="caption strong mt-2">Total Hours: {{totalHours(item)}}</span> -->
                      <span v-if="item.inTime && item.outTime" class="caption strong mt-2">Total Hours: {{totalHours2(item)}}</span>
                    </div>
                  </span>
                  <transition name="fade">
                    <div class="hidden-large mb-3 mt-3" v-if="(item.checkInTimeStamp || item.inTime) && !item.breakTime">
                      <button class="btn btn__outlined-primary btn__small" @click="giveBreak(item)">Give 30 Minute Break</button>
                    </div>
                  </transition>
                  <div class="mt-2 flex flex-row">
                    <!-- <transition name="fade">
                      <span class="ml-2" v-if="item.spin">
                      <i class="fa fa-spinner fa-spin fa-2x"></i>
                      </span>
                    </transition> -->
                    <span class="checkIn-buttons" style="width:100%;">

                      <div class="mr-5 hidden-small" style="padding-top: 22px;" v-if="(item.checkInTimeStamp || item.inTime) && !item.breakTime">
                        <button class="btn btn__outlined-primary btn__small" @click="giveBreak(item)">Give 30 Minute Break</button>
                      </div>
                      
                      <div v-if="!item.checkInTimeStamp && !item.inTime" class="mr-5" style="padding-top: 22px;">
                        <button class="btn btn__primary btn__small" @click="addInTime(item)">
                          Check In
                          <transition name="fade">
                            <span class="ml-2" v-if="item.spin">
                            <i class="fa fa-spinner fa-spin"></i>
                            </span>
                          </transition>
                        </button>
                      </div>

                      <span v-if="item.checkInTimeStamp || item.inTime" class="mr-5">
                        <label for="time">Adjust In Time</label>
                        <input type="time" v-model.trim="item.inTime" id="in" @blur="updateInTime(item)" />
                      </span>

                      <div v-if="!item.checkOutTimeStamp && !item.outTime" style="padding-top: 22px;">
                        <button class="btn btn__primary btn__small" @click="addOutTime(item)">
                          Check Out
                          <transition name="fade">
                            <span class="ml-2" v-if="item.spin">
                            <i class="fa fa-spinner fa-spin"></i>
                            </span>
                          </transition>
                        </button>
                      </div>

                      <span v-if="item.checkOutTimeStamp || item.outTime">
                        <label for="time">Adjust Out Time</label>
                        <input type="time" v-model.trim="item.outTime" id="in" @blur="updateOutTime(item)" />
                      </span>
                      
                    </span>
                  </div>
                </div>
              </div>
            </transition>
          </span>
        </div>


      </div>
    </div>
  </div>
</template>


<script>
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import * as moment from 'moment'
import router from '@/router'
// import StarRating from 'vue-star-rating'
// import algoliasearch from 'algoliasearch/lite';
// import ExportService from "@/services/ExportService"
// import CheckInModal from "@/components/CheckInModal.vue";
// import EditTimeModal from "@/components/EditTimeModal.vue";
const fb = require('../../firebaseConfig.js')

export default {
  props: ['eventInfo', 'eventShifts', 'eventShiftsDays'],
  name: 'eventCheckin',
  data() {
    return {
      performingRequest: false,
      performingRequest7: false,
      spin: false,
      newActiveDay: '',
      activeDay: '',
      modalValue: null,
      modalEditInValue: null,
      modalEditOutValue: null,
      columns2: [
        {
          label: '',
          field: 'preview',
          sortable: false,
          width:'120px',
        },
        {
          label: '',
          field: 'photoUrl',
          sortable: false,
          width:'48px',
        },
        {
          label: 'Name',
          field: 'fullName',
          sortable: false,
          width:'120px',
        },
        {
          label: 'Check-In',
          field: 'checkin',
          sortable: false,
          width: '90px',
          sortable: false,
        },
        {
          label: 'Check-Out',
          field: 'checkout',
          sortable: false,
          width: '90px',
          sortable: false,
        },
      ]
    }
  },
  components: {
    Loader,
  },
  computed: {
    ...mapState(['currentUser', 'eventUsers', 'userProfile']),
    event() {
      return this.eventInfo
    },
    filteredPlacedUsers () {
      return this.eventUsers.filter(user => {
        return ((user.status == 'placed') || (user.status == 'assigned'))
      })
    },
    activeShifts() {
      return this.eventShifts.filter(shift => {
        return (shift.day == this.activeDay || shift.day.includes(this.activeDay))
      })
    },
    // filteredShiftUsers(shift) {
    //   return this.eventUsers.filter(user => {
    //     return user.shift == shift.id && user.day == shift.day
    //   })
    // },
    // orderedPlacedUsers(shift) {
    //   return this.filteredShiftUsers(shift).filter(user => {
    //     return user.shift == shift.id && user.day == this.activeDay
    //   });
      
    // },
  },
  // mounted() {
  //   this.checkSet()
  // },
  methods: {
    // checkSet() {
    //   console.log('checkSet')
    //   console.log(moment().utcOffset())
    // },
    totalHours(item) {
      if (item.checkInTimeStamp && item.checkOutTimeStamp) {
        const inTime = new Date(item.checkInTimeStamp.seconds) * 1000;
        const outTime = new Date(item.checkOutTimeStamp.seconds) * 1000;
        const breakTaken = item.breakTime || 0
        const difference = outTime - inTime - breakTaken

        return moment.duration(difference).asHours().toFixed(2)
      } else {
        return ''
      }
    },
    totalHours2(item) {
      if (item.inTime && item.outTime) {
        const breakTaken = item.breakTime || 0
        let inTime = item.inTime
        let outTime = item.outTime 
        var inhoursMinutes = inTime.split(/[.:]/);
        var inhours = parseInt(inhoursMinutes[0], 10);
        var inminutes = inhoursMinutes[1] ? parseInt(inhoursMinutes[1], 10) : 0;
        var outhoursMinutes = outTime.split(/[.:]/);
        var outhours = parseInt(outhoursMinutes[0], 10);
        var outminutes = outhoursMinutes[1] ? parseInt(outhoursMinutes[1], 10) : 0;
        var totalHours = ((outhours + outminutes / 60).toFixed(2) - (inhours + inminutes / 60).toFixed(2) - breakTaken)
        return totalHours.toFixed(2)
      } else {
        return ''
      }
    },
    // totalShiftHours(shift) {
    //   let totalArr = []
    //     if(this.sortedShiftUsers(shift) && this.sortedShiftUsers(shift).length >= 1) {
    //     this.sortedShiftUsers(shift).forEach(item => {
    //       if (item.inTime && item.outTime) {
    //         const breakTaken = item.breakTime || 0
    //         let inTime = item.inTime
    //         let outTime = item.outTime 
    //         var inhoursMinutes = inTime.split(/[.:]/);
    //         var inhours = parseInt(inhoursMinutes[0], 10);
    //         var inminutes = inhoursMinutes[1] ? parseInt(inhoursMinutes[1], 10) : 0;
    //         var outhoursMinutes = outTime.split(/[.:]/);
    //         var outhours = parseInt(outhoursMinutes[0], 10);
    //         var outminutes = outhoursMinutes[1] ? parseInt(outhoursMinutes[1], 10) : 0;
    //         var totalHours = ((outhours + outminutes / 60).toFixed(2) - (inhours + inminutes / 60).toFixed(2) - breakTaken)
    //         totalArr.push(Number(totalHours))

    //         // const breakTaken = item.breakTime || 0
    //         // const difference = outTime - inTime - breakTaken

    //         // let number = moment.duration(difference).asHours().toFixed(2)
    //         // totalArr.push(Number(number))
    //       } else {
    //       }
    //     })
    //     if (totalArr.length >= 1) {
    //       let result = totalArr.reduce((sum, num) => sum + num);
    //       return result.toFixed(2)
    //     } else {
    //       return ''
    //     }
    //   }
    // },
    // async submitReport(shift) {
    //   shift.reportSending = true
    //   let startTime = moment(shift.startTime, "HH:mm").format('hh:mm A')
    //   let endTime = moment(shift.endTime, "HH:mm").format('hh:mm A')
    //   await this.$store.dispatch('sendNightlyReport', {
    //     type: 'nightlyReport',
    //     customerEmails: this.eventInfo.reportEmails || '',
    //     shift,
    //     workers: this.checkedInUsers(shift).length,
    //     total: this.totalShiftHours(shift),
    //     start: startTime,
    //     end: endTime,
    //   })
    //   setTimeout(() => {
    //     shift.reportSending = false
    //   }, 1000)
    // },
    removeIn(item) {
      this.$store.dispatch('deleteInTime', item)
    },
    removeOut(item) {
      this.$store.dispatch('deleteOutTime', item)
    },
    removeBreak(item) {
      this.$store.dispatch('cancelBreak', item)
    },
    giveBreak(item) {
      this.$store.dispatch('newBreak', item) 
    },
    // async addInTime(item) {
    //   item.spin = true
    //   var newCheckIn = firebase.functions().httpsCallable('newCheckIn');
    //   await newCheckIn(item)
    //   .then((result) => {
    //     console.log(result)
    //   })
    // },
    addInTime(item) {
      item.spin = true
      item.offset = moment().utcOffset()
      this.$store.dispatch('newCheckIn', item) 
    },
    addOutTime(item) {
      item.spin = true
      item.offset = moment().utcOffset()
      this.$store.dispatch('newCheckOut', item)
    },
    updateInTime(item) {
      item.spin = true
      item.offset = moment().utcOffset()
      this.$store.dispatch('updateCheckIn', item) 
    },
    updateOutTime(item) {
      item.spin = true
      item.offset = moment().utcOffset()
      this.$store.dispatch('updateCheckOut', item)
    },
    setActiveDay(day) {
      console.log('getting users for day')
      this.performingRequest = true
      this.$store.dispatch('clearEventUsersAndRef')
      if(day) {
        this.newActiveDay = day
        this.$store.dispatch('getEventUsers', {
          event: this.eventInfo,
          day: day
        })
      } else {
        this.$store.dispatch('clearEventUsersAndRef')
      }
    },
    expand(shift) {
      shift.collapse = false
      this.$store.dispatch("updateEventShift", shift)
    },
    collapse(shift) {
      shift.collapse = true
      this.$store.dispatch("updateEventShift", shift)
    },
    filteredShiftUsers(shift) {
      return this.eventUsers.filter(user => {
        return user.shift == shift.id && user.day == shift.day
      })
    },
    orderedPlacedUsers(shift) {
      return this.filteredShiftUsers(shift).filter(user => {
        return user.shift == shift.id && user.day == this.activeDay
      });
    },
    // },
    // sortedShiftUsers(shift) {
    //   return this.orderedPlacedUsers(shift).sort(function (a, b) {
    //     return ((a.firstName && a.firstName.toLowerCase()) - (b.firstName && b.firstName.toLowerCase()));
    //   });
    // },
    checkedInUsers(shift) {
      // function compare(a, b) {
      //   if (a.firstName < b.firstName)
      //     return -1;
      //   if (a.firstName > b.firstName)
      //     return 1;
      //   return 0;
      // }
      return this.filteredPlacedUsers.filter(user => {
        return user.shift == shift.id && (user.checkInTimeStamp || user.inTime)
      });
    },
    formatDate(q) {
      if(q) {
        const postedDate = new Date(q.seconds) * 1000;
        return moment(postedDate).utcOffset(postedDate).format('HH:mm')
      } else {
        return null
      }
    },
    formatAMPM(date) {
      if (typeof date === "string") {
        let [hours, minutes] = date.split(":");
        let ampm = "AM";

        if (Number(hours) >= 12) {
          hours = Number(hours) - 12;
          ampm = "PM";
        }

        return `${hours}:${minutes} ${ampm}`;

      } else if (date instanceof Date) {
        let hours = date.getHours();
        let minutes = date.getMinutes();

        let ampm = hours >= 12 ? "PM" : "AM";

        hours = hours % 12;
        hours = hours ? hours : 12;
        minutes = minutes < 10 ? "0" + minutes : minutes;

        let strTime = hours + ":" + minutes + " " + ampm;

        return strTime;
      }

      return date;
    },
    formatDate2(q) {
      if(q) {
        const postedDate = new Date(q.seconds) * 1000;
        let thedate = new Date(postedDate)
        // console.log(moment().utcOffset())
        let formattedDate = this.formatAMPM(thedate)
        return formattedDate
      } else {
        return null
      }
    },
  },
  beforeDestroy () {
    this.$store.dispatch('clearEventUsersAndRef')
    this.performingRequest = null
    delete this.performingRequest
    this.performingRequest7 = null
    delete this.performingRequest7
    this.newActiveDay = null
    delete this.newActiveDay
    this.spin = null
    delete this.spin
    this.activeDay = null
    delete this.activeDay
    this.modalValue = null
    delete this.modalValue
    this.modalEditInValue = null
    delete this.modalEditInValue
    this.modalEditOutValue = null
    delete this.modalEditOutValue
    this.columns2 = null
    delete this.columns2
    this.activeDay = null
    delete this.activeDay
    delete this.formatDate
    delete this.checkedInUsers
    delete this.sortedShiftUsers
    delete this.orderedPlacedUsers
    delete this.filteredShiftUsers
    delete this.collapse
    delete this.expand
    delete this.setActiveDay
    delete this.updateOutTime
    delete this.updateInTime
    delete this.addOutTime
    delete this.addInTime
    delete this.giveBreak
    delete this.removeBreak
    delete this.removeOut
    delete this.removeIn
    delete this.submitReport
    delete this.totalShiftHours
    delete this.totalHours
    delete this.totalHours2
  }
}
</script>